export interface MenuItem {
  text: string;
  icon: string;
  onClick: () => void;
  to: string; // Adicione a propriedade route
  subItems?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  // {
  //   text: "Página Inicial",
  //   icon: "home",
  //   onClick: () => {
  //     // Ação quando o item é clicado
  //   },
  //   to:"/pagina-inicial" // Exemplo de rota para a página inicial
  // },

  // {
  //   text: "Cadastros",
  //   icon: "add_circle_outline",
  //   onClick: () => {
  //     // Ação quando o item é clicado
  //   },
  //   to: "/cadastros", 
  //   subItems: [
  //     {
  //       text: "Unidades Atend.",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado  rgb(247 249 252)
  //       },
  //       to: "/unidade"
  //     },
  //     {
  //       text: "Profissionais",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/profissional"
  //     },
  //     {
  //       text: "Pacientes",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/paciente"
  //     },
  //     {
  //       text: "Setores",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/setor"
  //     },
  //     {
  //       text: "Quartos",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/quarto"
  //     },
  //     {
  //       text: "Leitos",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/leito"
  //     },
  //     {
  //       text: "Class. Doenças",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/cid"
  //     },
  //     {
  //       text: "Convênios",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/convenio"
  //     },
  //     {
  //       text: "Proc. SUS",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/procsus"
  //     },
  //     {
  //       text: "Proc. TUSS",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/proctuss"
  //     },
  //   ],
  // },
  


  {
    text: "Financeiro",
    icon: "assessmentIcon",
    onClick: () => {
      // Ação quando o item é clicado
    },
    to: "/financeiro",
    subItems: [
      // {
      //   text: "Dashboard",
      //   icon: "preferences",
      //   onClick: () => {
      //     // Ação quando o item é clicado
      //   },
      //   to: "/financeiro/dashboard"
      // },
      {
        text: "Contas a Pagar",
        icon: "preferences",
        onClick: () => {
          // Ação quando o item é clicado
        },
        to: "/financeiro/cpagar"
      },
      {
        text: "Contas a Receber",
        icon: "preferences",
        onClick: () => {
          // Ação quando o item é clicado
        },
        to: "/financeiro/creceber"
      },
      {
        text: "Fluxo de Caixa",
        icon: "preferences",
        onClick: () => {
          // Ação quando o item é clicado
        },
        to: "/financeiro/fluxocaixa"
      },
      {
        text: "Ret. Bancario",
        icon: "preferences",
        onClick: () => {
          // Ação quando o item é clicado
        },
        to: "/financeiro/retmentore"
      }
    ],
  },
  {
    text: "Operacional",
    icon: "assessmentIcon",
    onClick: () => {
      // Ação quando o item é clicado
    },
    to: "/financeiro",
    subItems: [
      // {
      //   text: "Dashboard",
      //   icon: "preferences",
      //   onClick: () => {
      //     // Ação quando o item é clicado
      //   },
      //   to: "/financeiro/dashboard"
      // },
      {
        text: "Aud. Efetivo",
        icon: "preferences",
        onClick: () => {
          // Ação quando o item é clicado
        },
        to: "/operacional/auditoria/efetivo"
      }
    ],
  },
  // {
  //   text: "Painel Chamada",
  //   icon: "desktop_windows",
  //   onClick: () => {
  //     // Ação quando o item é clicado
  //   },
  //   to: "/painel"
  // },

  // {
  //   text: "Atendimentos",
  //   icon: "assessmentIcon",
  //   onClick: () => {
  //     // Ação quando o item é clicado
  //   },
  //   to: "/relatorios",
  //   subItems: [
  //     {
  //       text: "Convênios",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/relatorios/convenio"
  //     },
  //     {
  //       text: "Médicos",
  //       icon: "preferences",
  //       onClick: () => {
  //         // Ação quando o item é clicado
  //       },
  //       to: "/relatorios/medico"
  //     },
  //   ],
  // },
  // Adicione mais itens conforme necessário
];