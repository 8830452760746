import { Api } from "../../axios-config";

export interface IAusencia {
  EVENTO: string;
  [key: string]: number | null | string;
}

const getAusenciasByFuncionario = async (
  chapa: string,
  codColigada: number
): Promise<IAusencia[] | Error> => {
  try {
    const { data } = await Api.get<IAusencia[]>(
      `/operacional/ausencias/${chapa}/${codColigada}`
    );

    if (Array.isArray(data)) {
      return data;
    }

    return new Error("Erro ao buscar ausências do funcionário.");
  } catch (error) {
    console.error("Erro ao buscar ausências do funcionário:", error);
    return new Error("Erro ao buscar ausências do funcionário.");
  }
};

export const AusenciasService = {
  getAusenciasByFuncionario,
};
