import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  LinearProgress,
  Grid,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { RetornoMentoreService } from "../../../shared/services/api/financeiro/retornoMentore/RetornoMentoreServices";
import { LayoutBasePagina } from "../../../shared/layouts";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SearchIcon from "@mui/icons-material/Search";
import ArchiveIcon from "@mui/icons-material/Archive";
interface ICSVRow {
  matricula: string;
  contrato: string;
  cpf: string;
  valor: string;
  cliente?: string;
  contratoAPI?: string;
  colaborador?: string;
  codigo?: string;
}

export const LerArquivoCSV: React.FC = () => {
  const [csvData, setCsvData] = useState<ICSVRow[]>([]);
  const [columns] = useState<GridColDef[]>([
    { field: "cpf", headerName: "CPF", flex: 1 },
    { field: "matricula", headerName: "MATRICULA", flex: 1 },
    { field: "contrato", headerName: "CONTRATO (CSV)", flex: 1 },
    { field: "contratoAPI", headerName: "CONTRATO (API)", flex: 1 },
    { field: "cliente", headerName: "CLIENTE", flex: 1 },
    { field: "colaborador", headerName: "COLABORADOR", flex: 1 },
    { field: "valor", headerName: "VALOR", flex: 1 },
  ]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      delimiter: ";",
      skipEmptyLines: true,
      complete: (result) => {
        const data = result.data as ICSVRow[];
        setCsvData(
          data.map((row) => ({
            cpf: row.cpf,
            matricula: row.matricula.padStart(8, "0"),
            contrato:
              row.contrato === "255" || row.contrato === "254"
                ? "12"
                : row.contrato,
            valor: row.valor,
            codigo: row.codigo,
          }))
        );
      },
      error: (error) => {
        console.error("Erro ao processar o arquivo CSV:", error);
      },
    });
  };

  const fetchClienteData = async () => {
    setLoading(true);
    setProgress(0);
    const updatedData = [...csvData];

    for (let i = 0; i < updatedData.length; i++) {
      const row = updatedData[i];
      try {
        const response = await RetornoMentoreService.getById(
          row.matricula,
          row.contrato
        );
        if (!(response instanceof Error)) {
          updatedData[i].cliente = response.CLIENTE;
          updatedData[i].contratoAPI = response.CONTRATO.toString();
          updatedData[i].colaborador = response.FUNCIONARIO;
        } else {
          updatedData[i].cliente = "Erro na consulta";
          updatedData[i].contratoAPI = "Erro";
          updatedData[i].colaborador = "Erro na consulta";
        }
      } catch (error) {
        console.error(
          `Erro ao consultar cliente para a matrícula ${row.matricula}:`,
          error
        );
        updatedData[i].cliente = "Erro inesperado";
        updatedData[i].contratoAPI = "Erro";
        updatedData[i].colaborador = "Erro inesperado";
      }
      setProgress(Math.round(((i + 1) / updatedData.length) * 100));
    }

    setCsvData(updatedData);
    setLoading(false);
  };

  const gerarPDFPorCliente = async () => {
    if (csvData.length === 0) return;

    const groupedData = csvData.reduce((acc, row) => {
      const clienteKey =
        row.contratoAPI + " - " + row.cliente || "Cliente não informado";
      if (!acc[clienteKey]) {
        acc[clienteKey] = [];
      }
      acc[clienteKey].push(row);
      return acc;
    }, {} as Record<string, ICSVRow[]>);

    const zip = new JSZip();
    const baseUrl =
      "https://empresa.mentorebank.com.br/mentore/pages/reciboPagamento_gerarRelatorio";

    for (const [cliente, rows] of Object.entries(groupedData)) {
      const doc = new jsPDF();
      const totalFuncionarios = rows.length;
      const totalValor = rows.reduce((sum, row) => {
        const valor = parseFloat(row.valor.replace(".", "").replace(",", "."));
        return sum + (isNaN(valor) ? 0 : valor);
      }, 0);

      const imgPath = `${window.location.origin}/mentore.png`;
      const imgWidth = 20;
      const imgHeight = 10;

      doc.addImage(imgPath, "PNG", 15, 10, imgWidth, imgHeight);
      doc.setFontSize(16);
      doc.text("COMPROVANTE DE PAGAMENTO", 105, 20, { align: "center" });
      doc.setDrawColor(165);
      doc.setLineWidth(0.3);
      doc.line(15, 25, 195, 25);
      doc.setFontSize(12);
      doc.text(`CLIENTE: ${cliente}`, 15, 30);
      doc.text(`QTD FUNCIONARIOS: ${totalFuncionarios}`, 15, 40);
      doc.text(
        `TOTAL: ${totalValor.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}`,
        15,
        50
      );
      doc.line(15, 55, 195, 55);

      const tableBody = rows.map((row) => {
        const codigoLink = row.codigo
          ? `${baseUrl}?objetoPesquisa.codigo=${row.codigo}&validacao=true`
          : null;
        return [
          row.cpf,
          row.colaborador || "N/A",
          { content: row.valor, styles: { halign: "right" } },
          codigoLink
            ? {
                content: "",
                styles: { textColor: [0, 0, 255] },
                link: codigoLink,
              }
            : "N/A",
        ];
      });

      doc.autoTable({
        startY: 60,
        head: [["CPF", "Funcionário", "Valor", "Código"]],
        body: tableBody,
        styles: { halign: "left" },
        headStyles: { fillColor: [22, 160, 133] },
        columnStyles: {
          2: { halign: "right" },
          3: { halign: "center" },
        },
        didDrawCell: (data: any) => {
          if (
            data.column.index === 3 &&
            data.cell.raw &&
            typeof data.cell.raw === "object" &&
            "link" in data.cell.raw
          ) {
            const link = (data.cell.raw as { link: string }).link;
            doc.textWithLink("Relatório", data.cell.x + 10, data.cell.y + 5, {
              url: link,
            });
          }
        },
      });

      const pdfBlob = doc.output("blob");
      zip.file(`${cliente}.pdf`, pdfBlob);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "Comprovantes.zip");
  };

  return (
    <LayoutBasePagina titulo="RETORNO BANCÁRIO">
      <Box width="100%" display="flex">
        <Grid container spacing={2} margin={2}>
          <Typography variant="h6" gutterBottom>
            MENTORE
          </Typography>
          <Grid item xs={12} sm={12} md={12}>
            <Paper sx={{ padding: 3, marginBottom: 3 }}>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFileIcon />}
              >
                Selecionar Arquivo CSV
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              {csvData.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginLeft: 2 }}
                  startIcon={<SearchIcon />}
                  onClick={fetchClienteData}
                  disabled={loading}
                >
                  {loading ? "Consultando..." : "Consultar Clientes"}
                </Button>
              )}
              {csvData.length > 0 && !loading && progress === 100 && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ marginLeft: 2 }}
                  startIcon={<ArchiveIcon />}
                  onClick={gerarPDFPorCliente}
                >
                  Gerar PDFs e ZIP
                </Button>
              )}
            </Paper>
            {loading && (
              <Paper sx={{ padding: 3, marginBottom: 3 }}>
                <Typography variant="body2">Progresso: {progress}%</Typography>
                <LinearProgress variant="determinate" value={progress} />
              </Paper>
            )}
            <Grid item xs={12} sm={12} md={12}>
              {csvData.length > 0 ? (
                <DataGrid
                  rows={csvData.map((row, index) => ({ id: index, ...row }))}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                  }}
                  pageSizeOptions={[10, 20, 50]}
                />
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Nenhum dado encontrado. Por favor, carregue um arquivo CSV.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LayoutBasePagina>
  );
};
