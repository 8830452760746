import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ITopFaltante } from "../../../../shared/services/api/operacional/ausencias/TopFaltantesServices";

interface StackedBarChartProps {
  data?: ITopFaltante[]; // Tornamos opcional e definimos default
  onFilter: (year: number, type: string) => void;
}

export const StackedBarChartFilter: React.FC<StackedBarChartProps> = ({
  data = [],
  onFilter,
}) => {
  const [chartOptions, setChartOptions] = useState<any>({});
  const [series, setSeries] = useState<any[]>([]);

  useEffect(() => {
    // Se não houver dados, definimos arrays default para os eixos
    if (!data || data.length === 0) {
      setSeries([
        { name: "ATESTADOS", data: [0] },
        { name: "FALTAS", data: [0] },
        { name: "SUSPENSAO", data: [0] },
      ]);
      setChartOptions({
        chart: { type: "bar", stacked: true, stackType: "100%" },
        // Definimos um array default com um item para evitar erros
        xaxis: { categories: ["No Data"] },
        yaxis: { categories: ["No Data"] },
      });
      return;
    }

    // Agrupar os dados por ano e somar os totais de cada tipo
    const groupByYear: Record<number, Record<string, number>> = {};
    data.forEach((item) => {
      const year = Number(item.ANO);
      if (isNaN(year)) return; // Ignora se ANO não for um número
      if (!groupByYear[year]) {
        groupByYear[year] = { ATESTADOS: 0, FALTAS: 0, SUSPENSAO: 0 };
      }
      const total = Number(item.TOTAL) || 0;
      if (item.TIPO === "ATESTADOS") {
        groupByYear[year].ATESTADOS += total;
      } else if (item.TIPO === "FALTAS") {
        groupByYear[year].FALTAS += total;
      } else if (item.TIPO === "SUSPENSAO") {
        groupByYear[year].SUSPENSAO += total;
      }
    });

    // Obter os anos válidos, ordená-los e convertê-los para string
    const yearsRaw = Object.keys(groupByYear)
      .map(Number)
      .filter((n) => !isNaN(n))
      .sort((a, b) => a - b);
    // Se não houver anos válidos, usamos um array default
    const years = yearsRaw.length > 0 ? yearsRaw.map(String) : ["No Data"];

    // As séries devem ter a mesma quantidade de pontos que as categorias
    const seriesData =
      yearsRaw.length > 0
        ? [
            {
              name: "ATESTADOS",
              data: yearsRaw.map((y) => groupByYear[y].ATESTADOS),
            },
            {
              name: "FALTAS",
              data: yearsRaw.map((y) => groupByYear[y].FALTAS),
            },
            {
              name: "SUSPENSAO",
              data: yearsRaw.map((y) => groupByYear[y].SUSPENSAO),
            },
          ]
        : [
            { name: "ATESTADOS", data: [0] },
            { name: "FALTAS", data: [0] },
            { name: "SUSPENSAO", data: [0] },
          ];

    // Configurar opções para um gráfico 100% stacked horizontal
    const options = {
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        events: {
          dataPointSelection: (
            event: any,
            chartContext: any,
            config: { dataPointIndex: number; seriesIndex: number }
          ) => {
            if (yearsRaw.length === 0) return;
            const selectedYear = yearsRaw[config.dataPointIndex];
            const selectedType = seriesData[config.seriesIndex].name;
            onFilter(selectedYear, selectedType);
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "center",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number, opts: any) {
          // opts.w.globals.series contém os valores originais
          const absoluteValue =
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
          return `${val.toFixed(0)}% (${absoluteValue})`;
        },
        style: {
          colors: ["#fff"],
        },
      },
      // Em gráfico horizontal, use o eixo X para as categorias (por padrão, ApexCharts inverte)
      xaxis: {
        categories: years, // deve ter o mesmo comprimento que cada série
        labels: {
          formatter: (val: number) => val.toFixed(0) + "%",
        },
      },
      // O eixo Y pode ser opcional (ou escondido) em gráficos horizontais
      yaxis: {
        // Se quiser mostrar os anos ao lado, pode usá-los aqui também
        categories: years,
      },
      legend: {
        position: "top",
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(0),
        },
      },
      colors: ["#4CAF50", "#FF5252", "#2196F3"],
    };

    console.log("Years:", years);
    console.log("Series Data:", seriesData);
    console.log("Chart Options:", options);

    setSeries(seriesData);
    setChartOptions(options);
  }, [data, onFilter]);

  // Renderize o Chart somente se chartOptions.xaxis.categories estiver definido
  if (
    !chartOptions.xaxis ||
    !chartOptions.xaxis.categories ||
    chartOptions.xaxis.categories.length === 0
  ) {
    return <div>Carregando gráfico...</div>;
  }

  return (
    <Chart options={chartOptions} series={series} type="bar" height={200} />
  );
};

export default StackedBarChartFilter;
