import { Api } from "../../axios-config";

export interface IAfastamento {
    DTINICIO: string; // Data de início formatada como DD/MM/YYYY
    DTFIM: string | null; // Data final formatada como DD/MM/YYYY
    DTFINAL: string | null; // Data final bruta
    TIPO: string; // Código do tipo de afastamento
    CODTIPO: string; // Código do tipo de afastamento (igual a TIPO)
    TIPOAFT: string; // Descrição do tipo de afastamento
    CODMOTIVO: string; // Código do motivo do afastamento
    MOTIVO: string; // Descrição do motivo do afastamento
    QTD_DIAS_AFASTADO: number; // Quantidade de dias afastado
    CID: string; // Descrição do CID ou "Nao Informado"
    TIPOAFA: string; // Tipo de afastamento fixo como "ATESTADO MEDICO"
    CODCOLIGADA: number; // Código da coligada
    CHAPA: string; // Identificação do funcionário
  }

const getHstAfastamento = async (chapa: string, codColigada: number): Promise<IAfastamento[] | Error> => {
    try {
        const { data } = await Api.get(`/operacional/ausencias/hstAfastamento/${chapa}/${codColigada}`);

        console.log(chapa, codColigada)

        if (Array.isArray(data)) {
            return data;
        }

        return new Error("Erro ao buscar resumo de ausências.");
    } catch (error) {
        console.error(error);
        return new Error("Erro ao buscar resumo de ausências.");
    }
};

export const HistoricoAfastamento = {
    getHstAfastamento,
};
