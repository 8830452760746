import { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { FuncionariosAusenciasService } from "../../../../shared/services/api/operacional/ausencias/FuncionariosAusenciaServices";
import { IFuncionariosAusencia } from "../../../../shared/services/api/operacional/ausencias/FuncionariosAusenciaServices";
import { useDebounce } from "../../../../shared/hooks";

interface Props {
  onSelect: (funcionario: IFuncionariosAusencia | null) => void;
}

export const FuncionarioSearch: React.FC<Props> = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<IFuncionariosAusencia[]>([]);
  const [loading, setLoading] = useState(false);

  const { debounce } = useDebounce(300); // 🔹 Usando debounce

  useEffect(() => {
    if (!inputValue.trim()) {
      setOptions([]);
      return;
    }

    debounce(async () => {
      setLoading(true);
      const result = await FuncionariosAusenciasService.getAll(1, inputValue);
      if (!(result instanceof Error)) {
        setOptions(result.data);
      }
      setLoading(false);
    });
  }, [inputValue]);

  return (
    <Autocomplete
      fullWidth
      options={options}
      getOptionLabel={(option) => option.FUNCIONARIO}
      loading={loading}
      onChange={(_, value) => onSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Pesquisar funcionário"
          variant="outlined"
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <Skeleton variant="rectangular" height={350} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
