import { Api } from "../../axios-config";

export interface IFuncionarioRetornoMentore {
    CHAPA: string;
    CODCOLIGADA: number;
    FUNCIONARIO: string;
    CONTRATO: number;
    CODCLIENTE: number;
    CLIENTE: string;
    CODPOSTO: string;
    POSTO: string;
}

// Função para buscar por CHAPA e CODCOLIGADA
const getById = async (chapa: string, codcoligada: string): Promise<IFuncionarioRetornoMentore | Error> => {
    try {
        // Realiza a requisição GET para a API
        const { data } = await Api.get(`/financeiro/retmentore/${chapa}/${codcoligada}`);

        // Verifica se há dados na resposta
        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};


export const RetornoMentoreService = {
    getById,
};
