import { Navigate, Route, Routes } from "react-router-dom";

import {
  Dashboard,
  ListagemDeContasPagar,
  AuditoriaEfetivoPage,
  ListagemDeContasReceber,
  FluxoDeCaixa,
  LerArquivoCSV,
} from "../pages";
import { useDrawerContext } from "../shared/contexts";

export const AppRoutes = () => {
  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Dashboard />} />{" "}
      {/* Nova rota para a página principal */}
      <Route
        path="/financeiro/cpagar"
        element={<ListagemDeContasPagar />}
      />{" "}
      <Route
        path="/operacional/auditoria/efetivo"
        element={<AuditoriaEfetivoPage />}
      />{" "}
      <Route
        path="/financeiro/creceber"
        element={<ListagemDeContasReceber />}
      />{" "}
      <Route path="/financeiro/fluxocaixa" element={<FluxoDeCaixa />} />{" "}
      <Route path="/financeiro/retmentore" element={<LerArquivoCSV />} />{" "}
      {/* Nova rota para a página de contas a pagar */}
      {/* <Route
        path="/financeiro/dashboard"
        element={<DashboardFinanceiro />}
      />{" "} */}
      {/* Nova rota para a página de contas a pagar */}
      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
