import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  Divider,
  AppBar,
  Toolbar,
  Skeleton,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  FuncionariosAusenciasService,
  IFuncionarioDetalhes,
} from "../../../shared/services/api/operacional/ausencias/FuncionariosAusenciaServices";
import {
  TopFaltantesService,
  ITopFaltante,
} from "../../../shared/services/api/operacional/ausencias/TopFaltantesServices";
import { LayoutBasePagina } from "../../../shared/layouts";
import { FuncionarioSearch } from "./components/FuncionarioSearch";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import WorkIcon from "@mui/icons-material/Work";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventIcon from "@mui/icons-material/Event";
import BusinessIcon from "@mui/icons-material/Business";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CakeIcon from "@mui/icons-material/Cake";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { CalendarioAusencias } from "./components/CalendarioAusencias";
import { ResumoAusenciasChart } from "./components/ResumoAusenciasChart";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SickIcon from "@mui/icons-material/Sick";
import { HistoricoAfastamentoGrid } from "./components/HistoricoAfastamento";
import { TopAtestados } from "./components/TopAtestados";
// Importa o componente do gráfico – certifique-se do caminho e da exportação default
import StackedBarChartFilter from "./components/TotalTopFaltantes";

const InfoItem: React.FC<{
  icon: React.ReactNode;
  label: string;
  value: string;
}> = ({ icon, label, value }) => (
  <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
    {icon}
    <Typography variant="body2">
      <b>{label}:</b> {value}
    </Typography>
  </Box>
);

export const ScoreCard: React.FC = () => {
  const [selectedFuncionario, setSelectedFuncionario] =
    useState<IFuncionarioDetalhes | null>(null);
  const [calendarKey, setCalendarKey] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  // Estados para os filtros do gráfico/ausências
  const [filterAno, setFilterAno] = useState<number>(2024);
  const [filterTipo, setFilterTipo] = useState<string>("ATESTADOS");

  // Estado para os dados usados no gráfico de filtro
  const [allTopFaltantes, setAllTopFaltantes] = useState<ITopFaltante[]>([]);
  const [loadingTop, setLoadingTop] = useState(true);

  // Busca os dados do TopFaltantes (para o gráfico)
  useEffect(() => {
    const fetchTopFaltantes = async () => {
      try {
        const result = await TopFaltantesService.getTopFaltantes();
        if (!(result instanceof Error)) {
          setAllTopFaltantes(result);
        }
      } catch (error: any) {
        toast.error("Erro ao buscar dados de ausências para o gráfico");
      } finally {
        setLoadingTop(false);
      }
    };
    fetchTopFaltantes();
  }, []);

  // Busca os detalhes do funcionário selecionado
  const fetchFuncionarioDetalhes = async (
    chapa: string,
    codColigada: number
  ) => {
    const result = await FuncionariosAusenciasService.getFuncionarioById(
      chapa,
      codColigada
    );
    if (result instanceof Error) {
      toast.error(result.message);
      setSelectedFuncionario(null);
    } else if (Array.isArray(result) && result.length > 0) {
      const funcionario = result[0];
      if (funcionario.IMAGEM && funcionario.IMAGEM.data) {
        const uint8Array = new Uint8Array(funcionario.IMAGEM.data);
        const blob = new Blob([uint8Array], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
        funcionario.IMAGEM = imageUrl;
      }
      setSelectedFuncionario(funcionario);
      setCalendarKey((prevKey) => prevKey + 1);
    } else {
      toast.warning("Nenhum funcionário encontrado.");
      setSelectedFuncionario(null);
    }
  };

  // Callback para o gráfico de filtro: atualiza os estados de filtro
  const handleChartFilter = (year: number, type: string) => {
    setFilterAno(year);
    setFilterTipo(type);
  };

  // Após obter os dados do funcionário, encerra o loading (se necessário)
  useEffect(() => {
    setIsLoading(false);
  }, [selectedFuncionario]);

  return (
    <LayoutBasePagina titulo="SCORECARD">
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3} mt={2}>
          {/* Seção de Busca e Detalhes do Funcionário */}
          <Grid item xs={4}>
            <FuncionarioSearch
              onSelect={(funcionario) => {
                if (funcionario) {
                  fetchFuncionarioDetalhes(
                    funcionario.CHAPA,
                    funcionario.CODCOLIGADA
                  );
                }
              }}
            />
          </Grid>
          {selectedFuncionario && (
            <Grid item xs={12}>
              <AppBar
                position="static"
                sx={{
                  background: "linear-gradient(45deg,#FE6B8B 30%, #FF8E53 90%)",
                  boxShadow: "none",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Toolbar>
                  <Typography variant="h6">
                    INFORMAÇÕES DO COLABORADOR
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  borderRadius: "0 0 8px 8px",
                  background: "rgba(255, 255, 255, 0.8)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={3}
                    display="flex"
                    justifyContent="center"
                  >
                    <Avatar
                      src={selectedFuncionario.IMAGEM || ""}
                      sx={{
                        width: 150,
                        height: 150,
                        border: "3px solid #7b1fa2",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Typography variant="h5" fontWeight="bold" color="GrayText">
                      {selectedFuncionario.CHAPA} -{" "}
                      {selectedFuncionario.FUNCIONARIO}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <InfoItem
                          icon={<BeachAccessIcon color="action" />}
                          label="SITUAÇÃO"
                          value={
                            selectedFuncionario.SITUACAO || "Não informado"
                          }
                        />
                        <InfoItem
                          icon={<PersonIcon color="action" />}
                          label="CPF"
                          value={selectedFuncionario.CPF || "Não informado"}
                        />
                        <InfoItem
                          icon={<EventIcon color="action" />}
                          label="Idade"
                          value={`${selectedFuncionario.IDADE || "--"} anos`}
                        />
                        <InfoItem
                          icon={<CalendarTodayIcon color="action" />}
                          label="Admissão"
                          value={
                            selectedFuncionario.DATAADMISSAO || "Não informado"
                          }
                        />
                        <InfoItem
                          icon={<AccountBalanceWalletIcon color="action" />}
                          label="Tempo de Casa"
                          value={
                            selectedFuncionario.TEMPO_CASA_ANOS.toString() +
                            " Anos e " +
                            selectedFuncionario.TEMPO_CASA_MESES.toString() +
                            " meses"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InfoItem
                          icon={<BusinessIcon color="action" />}
                          label="Empresa"
                          value={selectedFuncionario.EMPRESA || "Não informado"}
                        />
                        <InfoItem
                          icon={<WorkIcon color="action" />}
                          label="Função"
                          value={selectedFuncionario.FUNCAO || "Não informado"}
                        />
                        <InfoItem
                          icon={<ApartmentIcon color="action" />}
                          label="Posto"
                          value={selectedFuncionario.POSTO || "Não informado"}
                        />
                        <InfoItem
                          icon={<AccountBalanceWalletIcon color="action" />}
                          label="Salário"
                          value={`R$ ${selectedFuncionario.SALARIO ?? ""}`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Seções de Calendário, Resumo e Histórico */}
                <Grid container spacing={2} mt={4}>
                  <Grid item xs={12} md={8}>
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                      <CalendarMonthIcon color="primary" />
                      <Typography variant="h6" fontWeight="bold">
                        CALENDÁRIO DE AUSÊNCIAS
                      </Typography>
                    </Box>
                    <Divider />
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        height: 350,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "97%",
                      }}
                    >
                      <Box sx={{ width: "97%", height: "100%" }}>
                        <CalendarioAusencias
                          key={calendarKey}
                          chapa={selectedFuncionario.CHAPA}
                          codColigada={selectedFuncionario.CODCOLIGADA}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                      <SickIcon color="primary" />
                      <Typography variant="h6" fontWeight="bold">
                        TOTALIZADOR DESDE JAN/2023
                      </Typography>
                    </Box>
                    <Divider />
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        height: 350,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ResumoAusenciasChart
                        chapa={selectedFuncionario.CHAPA}
                        codColigada={selectedFuncionario.CODCOLIGADA}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                      <SickIcon color="primary" />
                      <Typography variant="h6" fontWeight="bold">
                        HISTÓRICO DE AFASTAMENTO
                      </Typography>
                    </Box>
                    <Divider />
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        height: 350,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <HistoricoAfastamentoGrid
                        chapa={selectedFuncionario.CHAPA}
                        codColigada={selectedFuncionario.CODCOLIGADA}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          {/* Seção de Informações de Ausências com Filtro */}
          <Grid item xs={12}>
            <AppBar
              position="static"
              sx={{
                background: "linear-gradient(45deg,#FE6B8B 30%, #FF8E53 90%)",
                boxShadow: "none",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Toolbar>
                <Typography variant="h6">
                  TOP 15 - ATESTADOS, FALTAS E SUSPENSÕES
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ my: 2 }}>
              {loadingTop ? (
                <Skeleton variant="rectangular" height={350} />
              ) : (
                <StackedBarChartFilter
                  data={allTopFaltantes || []}
                  onFilter={handleChartFilter}
                />
              )}
            </Box>
            <TopAtestados
              onSelectFuncionario={fetchFuncionarioDetalhes}
              filterAno={filterAno}
              filterTipo={filterTipo}
            />
          </Grid>
        </Grid>
      </Box>
    </LayoutBasePagina>
  );
};

export default ScoreCard;
