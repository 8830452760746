import { Api } from "../../axios-config";

export interface ITopFaltante {
  CODCOLIGADA: string;
  CHAPA: string;
  NOME: string;
  TIPO: string;
  ANO: string;
  jan: number;
  fev: number;
  mar: string;
  abr: number;
  mai: number;
  jun: number;
  jul: number;
  ago: number;
  set: number;
  out: number;
  nov: number;
  dez: number;
  TOTAL: number;
}

const getTopFaltantes = async (
  ano?: string,
  tipo?: string,
  page?: number,
  limit?: number,
  semPaginacao?: boolean
): Promise<ITopFaltante[] | Error> => {
  try {
    const { data } = await Api.get<ITopFaltante[]>("/operacional/ausencias/topfaltantes", {
      params: { ano, tipo, page, limit, semPaginacao },
    });

    if (Array.isArray(data)) {
      return data;
    }

    return new Error("Erro ao buscar os top faltantes.");
  } catch (error) {
    console.error("Erro ao buscar os top faltantes:", error);
    return new Error("Erro ao buscar os top faltantes.");
  }
};

export const TopFaltantesService = {
  getTopFaltantes,
};
