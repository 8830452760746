import { Enviroment } from "../../../../environment";
import { Api } from "../../axios-config";

export interface IFuncionariosAusencia {
    EMPRESA: string; // Nome da empresa (C&S MATRIZ, C&S FILIAL, SCE SERVIÇOS)
    CODCOLIGADA: number; // Código da coligada
    CHAPA: string; // Código do funcionário (CHAPA)
    DATAADMISSAO: string; // Data de admissão (YYYY-MM-DD)
    DTNASCIMENTO: string; // Data de nascimento (YYYY-MM-DD)
    FUNCIONARIO: string; // Nome do funcionário
    IDIMAGEM: number | null; // ID da imagem do funcionário
    IMAGEM: string | null; // Base64 ou caminho da imagem
    CPF: string; // CPF do funcionário
    SITUACAO: string; // Descrição da situação do funcionário
    CODSECAO: string; // Código da seção
    POSTO: string; // Nome da seção/posto do funcionário
    SALARIO: number; // Salário do funcionário
    TEMPO_CASA_ANOS: number; // Tempo de casa (em anos)
    TEMPO_CASA_MESES: number; // Tempo de casa (em meses)
    IDADE: number; // Idade do funcionário
    FUNCAO: string; // Nome da função do funcionário
}

export interface IFuncionarioDetalhes {
    EMPRESA: string;
    CODCOLIGADA: number;
    CHAPA: string;
    DATAADMISSAO: string;
    DTNASCIMENTO: string;
    FUNCIONARIO: string;
    IDIMAGEM: number | null;
    IMAGEM: string | null; // Base64 da imagem
    CPF: string;
    SITUACAO: string;
    CODSECAO: string;
    POSTO: string;
    SALARIO: number;
    TEMPO_CASA_ANOS: number;
    TEMPO_CASA_MESES: number;
    IDADE: number;
    FUNCAO: string;
}


type TListagemComTotalFuncionariosAusenciaServices = {
    data: IFuncionariosAusencia[];
    totalCount: number;
}

const getAll = async (
    page = 1, 
    filter = ''
): Promise<TListagemComTotalFuncionariosAusenciaServices | Error> => {
    try {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', Enviroment.LIMITE_DE_LINHAS.toString());

        if (filter) {
            params.append('filter', filter); // 🛠 Inclui o filtro corretamente (Nome, CPF ou CHAPA)
        }

        // 🔹 Endpoint correto
        const { data, headers } = await Api.get(`/operacional/ausencias/funcionario?${params.toString()}`);
        
        if (data) {
            return {
                data,
                totalCount: Number(headers['x-total-count'] || 0),                
            };
        }

        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

export const getFuncionarioById = async (
    chapa: string,
    codColigada: number,
): Promise<IFuncionarioDetalhes | Error> => {
    try {
        const { data } = await Api.get(`/operacional/ausencias/funcionario/${chapa}/${codColigada}`);
        
        if (data) {
            return data;
        }
        return new Error("Erro ao buscar as informações do funcionário.");
    } catch (error) {
        console.error("Erro ao buscar funcionário:", error);
        return new Error((error as { message: string }).message || "Erro ao buscar funcionário.");
    }
};


export const FuncionariosAusenciasService = {
    getAll,
    getFuncionarioById
};
