import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CircularProgress, Alert, Box, Paper, Typography } from "@mui/material";
import {
  HistoricoAfastamento,
  IAfastamento,
} from "../../../../shared/services/api/operacional/ausencias/HistoricoAfastamento";

interface HistoricoAfastamentoProps {
  chapa: string;
  codColigada: number;
}

export const HistoricoAfastamentoGrid = ({
  chapa,
  codColigada,
}: HistoricoAfastamentoProps) => {
  const [historico, setHistorico] = useState<IAfastamento[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHistorico = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await HistoricoAfastamento.getHstAfastamento(
          chapa,
          codColigada
        );
        if (response instanceof Error) {
          setError(response.message);
        } else {
          setHistorico(response);
        }
      } catch (err) {
        setError("Erro ao buscar histórico de afastamento.");
      } finally {
        setLoading(false);
      }
    };

    fetchHistorico();
  }, [chapa, codColigada]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!historico || historico.length === 0)
    return <Alert severity="info">Nenhum afastamento encontrado.</Alert>;

  const columns: GridColDef[] = [
    { field: "DTINICIO", headerName: "Início", width: 120 },
    { field: "DTFIM", headerName: "Fim", width: 120 },
    { field: "TIPOAFT", headerName: "Tipo", width: 200 },
    { field: "MOTIVO", headerName: "Motivo", width: 300 },
    { field: "CID", headerName: "CID", width: 250 },
    {
      field: "QTD_DIAS_AFASTADO",
      headerName: "Dias Afastado",
      type: "number",
      width: 130,
    },
  ];

  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={historico.map((row, index) => ({ id: index, ...row }))}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
      />
    </Box>
  );
};
