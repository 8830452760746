import { Api } from "../../axios-config";

export interface IResumoAusencias {
    CHAPA: string;
    NOME: string;
    QTD: number;
    TIPO: string;
    SITUACAO: string;
    CODCOLIGADA: number;
}

const getResumoAusencias = async (chapa: string, codColigada: number): Promise<IResumoAusencias[] | Error> => {
    try {
        const { data } = await Api.get(`/operacional/ausencias/res/${chapa}/${codColigada}`);

        if (Array.isArray(data)) {
            return data;
        }

        return new Error("Erro ao buscar resumo de ausências.");
    } catch (error) {
        console.error(error);
        return new Error("Erro ao buscar resumo de ausências.");
    }
};

export const ResumoAusenciasService = {
    getResumoAusencias,
};
