import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import { ApexOptions } from "apexcharts";
import {
  IResumoAusencias,
  ResumoAusenciasService,
} from "../../../../shared/services/api/operacional/ausencias/ResumoAusenciaServices";

interface Props {
  chapa: string;
  codColigada: number;
}

export const ResumoAusenciasChart: React.FC<Props> = ({
  chapa,
  codColigada,
}) => {
  const [resumo, setResumo] = useState<IResumoAusencias[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResumo = async () => {
      setLoading(true);
      const result = await ResumoAusenciasService.getResumoAusencias(
        chapa,
        codColigada
      );

      if (!(result instanceof Error)) {
        setResumo(result);
      }
      console.log(result);
      setLoading(false);
    };

    fetchResumo();
  }, [chapa, codColigada]);

  // Se ainda está carregando, mostra o Loader
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={250}
      >
        <Skeleton variant="rectangular" height={350} />
      </Box>
    );
  }

  // Se não houver dados
  if (!resumo.length) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        sx={{ mt: 2 }}
      >
        Nenhuma ausência registrada.
      </Typography>
    );
  }

  // Preparando os dados para o gráfico
  const series = resumo.map((item) => item.QTD);
  const labels = resumo.map((item) => item.TIPO);

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: labels,
    colors: ["#f44336", "#ff9800", "#03a9f4"], // 🔴🟠🔵
    legend: {
      position: "bottom",
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} eventos`,
      },
    },
  };

  return <Chart options={options} series={series} type="donut" height={300} />;
};
