import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "primereact/resources/themes/lara-light-blue/theme.css"; // Tema do PrimeReact
import "primereact/resources/primereact.min.css"; // Estilos básicos
import "primeicons/primeicons.css"; // Ícones

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
