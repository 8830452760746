import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridColumnGroupingModel,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { DreService } from "../../../shared/services/api/gerencial/DreServices";
import { v4 as uuidv4 } from "uuid";
import { LayoutBasePagina } from "../../../shared/layouts";
import { FerramentasDaListagem } from "../../../shared/components";
import { Box, Grid } from "@mui/material";

interface Cliente {
  nome: string;
  idexternodocliente: string;
  valores: { [mes: string]: number };
}

interface Subgrupo {
  nome: string;
  clientes: Cliente[];
}

interface TipoReceita {
  descricao: string;
  subgrupos: Subgrupo[];
}

interface Categoria {
  categoria: string;
  tiposReceita: TipoReceita[];
}

interface RowData {
  id: string;
  cliente: string;
  idexternodocliente?: string;
  categoria: string;
  descricao: string;
  subgrupo: string;
  [key: string]: any;
}

/**
 * Mantém a lógica interna de custos negativos para cálculo,
 * mas exibe cada célula como positivo ou negativo conforme o valor real.
 */
const transformarValores = (valores: Record<string, number>) => {
  const novoObj: Record<string, number> = {};
  Object.entries(valores).forEach(([mes, valor]) => {
    novoObj[`${mes}_Atual`] = valor;
    novoObj[`${mes}_Percentual`] = 0;
    novoObj[`${mes}_Orcamento`] = 0;
    novoObj[`${mes}_PercentualOrc`] = 0;
  });
  return novoObj;
};

const transformarValoresCusto = (valores: Record<string, number>) => {
  const novoObj: Record<string, number> = {};
  Object.entries(valores).forEach(([mes, valor]) => {
    novoObj[`${mes}_Atual`] = -valor; // internamente negativo
    novoObj[`${mes}_Percentual`] = 0;
    novoObj[`${mes}_Orcamento`] = 0;
    novoObj[`${mes}_PercentualOrc`] = 0;
  });
  return novoObj;
};

export const DreReceitaMensal = () => {
  const [data, setData] = useState<Categoria[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    DreService.getAll()
      .then((response) => {
        if (response instanceof Error) {
          console.error("Erro ao buscar dados:", response.message);
        } else {
          console.log("Dados do backend:", response);
          setData(response);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // Define meses ativos até o mês atual
  const currentMonth = new Date().getMonth();
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const activeMonths = months.slice(0, currentMonth + 1);

  // Calcula totais
  const calcularTotais = (categorias: Categoria[]) => {
    const totais: { [key: string]: { [mes: string]: number } } = {};
    categorias.forEach((categoria) => {
      const categoriaKey = categoria.categoria;
      totais[categoriaKey] = totais[categoriaKey] || {};

      categoria.tiposReceita.forEach((tipo) => {
        const tipoKey = `${categoriaKey}-${tipo.descricao}`;
        totais[tipoKey] = totais[tipoKey] || {};

        tipo.subgrupos.forEach((subgrupo) => {
          if (subgrupo.nome === "Total de Impostos") return;
          const subgrupoKey = `${tipoKey}-${subgrupo.nome}`;
          totais[subgrupoKey] = totais[subgrupoKey] || {};

          subgrupo.clientes.forEach((cliente) => {
            Object.entries(cliente.valores).forEach(([mes, valor]) => {
              totais[subgrupoKey][mes] =
                (totais[subgrupoKey][mes] || 0) + valor;
              totais[tipoKey][mes] = (totais[tipoKey][mes] || 0) + valor;
              totais[categoriaKey][mes] =
                (totais[categoriaKey][mes] || 0) + valor;
            });
          });
        });
      });
    });
    return totais;
  };

  const totais = calcularTotais(data);

  // Ordena categorias
  const ordemCategorias = [
    "Receita Bruta",
    "Dedução dos Impostos",
    "Receita Líquida de Serviços",
    "Custo de servicos prestados",
    "Provisoes e encargos",
    "Provisoes e encargos s/ provisoes",
  ];
  const sortedData = [...data].sort((a, b) => {
    const idxA = ordemCategorias.indexOf(a.categoria);
    const idxB = ordemCategorias.indexOf(b.categoria);
    return idxA - idxB;
  });

  // Monta rows
  const rows: RowData[] = sortedData.flatMap((categoria) => {
    const isCusto = categoria.categoria === "Custo de servicos prestados";
    const transformFunction = isCusto
      ? transformarValoresCusto
      : transformarValores;

    const totalCategoria = totais[categoria.categoria] || {};
    const totalCategoriaCalculado = isCusto
      ? Object.values(totalCategoria).reduce((acc, val) => acc + val, 0) * -1
      : Object.values(totalCategoria).reduce((acc, val) => acc + val, 0);

    const categoriaRow: RowData = {
      id: uuidv4(),
      categoria: categoria.categoria,
      descricao: "",
      subgrupo: "",
      cliente: "",
      ...transformFunction(totalCategoria),
      Total_Anual: totalCategoriaCalculado,
    };

    return [
      categoriaRow,
      ...categoria.tiposReceita.flatMap((tipo) => {
        const tipoKey = `${categoria.categoria}-${tipo.descricao}`;
        const totalTipo = totais[tipoKey] || {};
        const totalTipoCalculado = isCusto
          ? Object.values(totalTipo).reduce((acc, val) => acc + val, 0) * -1
          : Object.values(totalTipo).reduce((acc, val) => acc + val, 0);

        const tipoRow: RowData = {
          id: uuidv4(),
          categoria: categoria.categoria,
          descricao: tipo.descricao,
          subgrupo: "",
          cliente: "",
          ...transformFunction(totalTipo),
          Total_Anual: totalTipoCalculado,
        };

        return [
          tipoRow,
          ...tipo.subgrupos.flatMap((subgrupo) => {
            if (subgrupo.nome === "Total de Impostos") return [];
            const subgrupoKey = `${tipoKey}-${subgrupo.nome}`;
            const totalSubgrupo = totais[subgrupoKey] || {};
            const totalSubgrupoCalculado = isCusto
              ? Object.values(totalSubgrupo).reduce(
                  (acc, val) => acc + val,
                  0
                ) * -1
              : Object.values(totalSubgrupo).reduce((acc, val) => acc + val, 0);

            const subgrupoRow: RowData = {
              id: uuidv4(),
              categoria: categoria.categoria,
              descricao: tipo.descricao,
              subgrupo: subgrupo.nome,
              cliente: "",
              ...transformFunction(totalSubgrupo),
              Total_Anual: totalSubgrupoCalculado,
            };

            return [
              subgrupoRow,
              ...subgrupo.clientes.map((cliente) => {
                const totalCliente = cliente.valores;
                return {
                  id: uuidv4(),
                  categoria: categoria.categoria,
                  descricao: tipo.descricao,
                  subgrupo: subgrupo.nome,
                  cliente: cliente.nome,
                  ...transformFunction(totalCliente),
                  Total_Anual: Object.values(totalCliente).reduce(
                    (acc, val) => acc + val,
                    0
                  ),
                };
              }),
            ];
          }),
        ];
      }),
    ];
  });

  // Nova linha: TOTAL GASTO COM PESSOAS (custo + provisões)
  const gastoPessoasRowValores: Record<string, number> = {};
  activeMonths.forEach((mes) => {
    const custoAbs = Math.abs(
      totais["Custo de servicos prestados"]?.[mes] || 0
    );
    const provisoesAbs = Math.abs(
      totais["Provisoes e encargos s/ provisoes"]?.[mes] || 0
    );
    gastoPessoasRowValores[mes] = -(custoAbs + provisoesAbs);
  });
  const gastoPessoasRow: RowData = {
    id: uuidv4(),
    categoria: "",
    descricao: "TOTAL GASTO COM PESSOAS",
    subgrupo: "",
    cliente: "",
    ...transformarValores(gastoPessoasRowValores),
    Total_Anual: activeMonths.reduce(
      (acc, mes) => acc + (gastoPessoasRowValores[mes] || 0),
      0
    ),
  };

  // Linha LUCRO BRUTO: Receita Líquida de Serviços – (Custo de servicos prestados + Provisões)
  const resultadoRowValores: Record<string, number> = {};
  activeMonths.forEach((mes) => {
    const receita = totais["Receita Líquida de Serviços"]?.[mes] || 0;
    // Como o gasto com pessoas já está como valor negativo, somamos
    const gastoPessoas = gastoPessoasRowValores[mes] || 0;
    resultadoRowValores[mes] = receita + gastoPessoas;
  });
  const resultadoRow: RowData = {
    id: uuidv4(),
    categoria: "",
    descricao: "LUCRO BRUTO",
    subgrupo: "",
    cliente: "",
    ...transformarValores(resultadoRowValores),
    Total_Anual: activeMonths.reduce(
      (acc, mes) => acc + (resultadoRowValores[mes] || 0),
      0
    ),
  };

  // Ordem final das linhas: TOTAL GASTO COM PESSOAS e depois LUCRO BRUTO
  const rowsFinal = [...rows, gastoPessoasRow, resultadoRow];

  /**
   * Formata os valores com o sinal real (positivo/negativo).
   * Sem usar Math.abs.
   */
  const formatCurrency = (value: number) => {
    if (value == null || isNaN(value)) return "R$ 0,00";
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Definição das colunas com "renderCell" para colorir texto: vermelho (negativo) e preto (ou verde) para positivo
  const columns: GridColDef[] = [
    ...activeMonths.flatMap((month) => [
      {
        field: `${month}_Orcamento`,
        headerName: "Orçado",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, number>) => {
          const val = params.value ?? 0;
          const color = val < 0 ? "red" : "black";
          return <span style={{ color }}>{formatCurrency(val)}</span>;
        },
      },
      {
        field: `${month}_Atual`,
        headerName: "Realizado",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, number>) => {
          const val = params.value ?? 0;
          const color = val < 0 ? "red" : "black";
          return <span style={{ color }}>{formatCurrency(val)}</span>;
        },
      },
      {
        field: `${month}_Percentual`,
        headerName: "%",
        flex: 1,
        renderCell: (params: GridRenderCellParams<any, number>) => {
          const val = params.value ?? 0;
          const color = val < 0 ? "red" : "black";
          return <span style={{ color }}>{formatCurrency(val)}</span>;
        },
      },
    ]),
  ];

  // Agrupamento das colunas
  const columnGroupingModel: GridColumnGroupingModel = activeMonths.map(
    (month) => ({
      groupId: month,
      headerName: month,
      children: [
        { field: `${month}_Orcamento`, headerName: "Orçado" },
        { field: `${month}_Atual`, headerName: "Realizado" },
        { field: `${month}_Percentual`, headerName: "%" },
      ],
    })
  );

  // Estilização
  const getRowClassName = (params: GridRowParams) => {
    if (!params.row.descricao && !params.row.subgrupo && !params.row.cliente) {
      switch (params.row.categoria) {
        case "Receita Bruta":
          return "my-theme--receita-bruta";
        case "Dedução dos Impostos":
          return "my-theme--deducoes";
        case "Receita Líquida de Serviços":
          return "my-theme--receita-liquida";
        default:
          return "";
      }
    }
    return "";
  };

  return (
    <LayoutBasePagina
      titulo="DEMONSTRATIVO DE RESULTADOS"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarBotaoNovo={false}
          mostrarInputBusca={false}
          mostrarDatas={false}
        />
      }
    >
      <Box width="99%" display="flex">
        <Grid container spacing={2} margin={2}>
          <DataGridPro
            rows={rowsFinal}
            columns={columns}
            columnGroupingModel={columnGroupingModel}
            loading={loading}
            treeData
            getTreeDataPath={(row) =>
              [row.categoria, row.descricao, row.subgrupo, row.cliente].filter(
                Boolean
              )
            }
            groupingColDef={{ headerName: "DRE", flex: 4 }}
            disableRowSelectionOnClick
            getRowClassName={getRowClassName}
            rowSpacingType="margin"
            getRowSpacing={(params) => {
              const row = params.model as RowData;
              // Espaço antes do "LUCRO BRUTO"
              if (row.descricao === "LUCRO BRUTO") {
                return { top: 40, bottom: 0 };
              }
              // Espaço antes de "Custo de servicos prestados"
              if (
                !row.descricao &&
                !row.subgrupo &&
                !row.cliente &&
                row.categoria === "Custo de servicos prestados"
              ) {
                return { top: 40, bottom: 0 };
              }
              return { top: 0, bottom: 0 };
            }}
            sx={{
              "& .my-theme--receita-liquida": {
                //backgroundColor: "#B3B3B3",
                //color: "white",
              },
              "& .MuiDataGrid-columnHeader--filledGroup": {
                backgroundColor: "#5D73E5",
                color: "white",
              },
            }}
          />
        </Grid>
      </Box>
    </LayoutBasePagina>
  );
};

export default DreReceitaMensal;
