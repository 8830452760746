import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  Skeleton,
} from "@mui/material";
import { AusenciasService } from "../../../../shared/services/api/operacional/ausencias/CalendarioAusenciaServices";
import { ApexOptions } from "apexcharts"; // Importa a tipagem correta do ApexCharts

interface IAusencia {
  EVENTO: string;
  [key: string]: number | string | null;
}

interface Props {
  chapa: string;
  codColigada: number;
}

export const CalendarioAusencias: React.FC<Props> = ({
  chapa,
  codColigada,
}) => {
  const [ausencias, setAusencias] = useState<IAusencia[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAusencias = async () => {
      setLoading(true);
      const result = await AusenciasService.getAusenciasByFuncionario(
        chapa,
        codColigada
      );
      if (!(result instanceof Error)) {
        setAusencias(result);
      }
      setLoading(false);
    };

    fetchAusencias();
  }, [chapa, codColigada]);

  // Se ainda está carregando, mostra o Loader
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={250}
      >
        <Skeleton variant="rectangular" height={350} />
      </Box>
    );
  }

  // Se não houver ausências registradas
  if (!ausencias.length) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        sx={{ mt: 2 }}
      >
        Nenhuma ausência registrada nos últimos 12 meses.
      </Typography>
    );
  }

  // Extrai os meses e dados para o gráfico
  const meses = Object.keys(ausencias[0] || {}).filter(
    (key) => key !== "EVENTO"
  );

  // Converte os dados das ausências para formato de séries do ApexCharts
  const series = ausencias.map((item) => ({
    name: item.EVENTO,
    data: meses.map((mes) => (item[mes] ? Number(item[mes]) : 0)), // Converte valores nulos para 0
  }));

  // ✅ Correção: Definição correta do `ApexOptions`
  const options: ApexOptions = {
    chart: {
      type: "bar", // 🔹 Agora está corretamente tipado como um dos valores permitidos
      toolbar: {
        show: false,
      },
    },
    colors: ["#f44336", "#ff9800", "#03a9f4"], // 🔴🟠🔵 Cores diferentes para eventos
    xaxis: {
      categories: meses, // Meses no eixo X
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: true, // ✅ Habilita exibição de valores dentro das barras
      style: {
        fontSize: "12px",
        colors: ["#FFF"], // Cor do texto dentro das barras
      },
      formatter: (val: number) => (val > 0 ? `${val}` : ""), // Mostra apenas valores maiores que 0
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} dias`,
      },
    },
  };

  return <Chart options={options} series={series} type="bar" height={300} />;
};
