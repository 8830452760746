import { Api } from "../axios-config";

// Interfaces ajustadas conforme os dados do backend
export interface ICliente {
    valores: {};
    idexternodocliente: string;
    nome: string;
    valor: number;
}

export interface ISubgrupo {
    nome: string;
    clientes: ICliente[];
}

export interface ITipoReceita {
    descricao: string;
    subgrupos: ISubgrupo[];
}

export interface ICategoria {
    categoria: 'Receita Bruta' | 'Dedução dos Impostos' | 'Receita Líquida de Serviços';
    tiposReceita: ITipoReceita[];
}

// Serviço para busca sem paginação, agora ordenando os dados corretamente
const getAll = async (): Promise<ICategoria[] | Error> => {
    try {
        const requestUrl = `/gerencial/dre?semPaginacao=true`;
        const { data } = await Api.get(requestUrl);

        if (data) {
            // 🔹 Definimos a ordem manualmente
            const orderedData = data.sort((a: ICategoria, b: ICategoria) => {
                const order = [
                    "Receita Bruta",
                    "Dedução dos Impostos",
                    "Receita Líquida de Serviços"
                ];
                return order.indexOf(a.categoria) - order.indexOf(b.categoria);
            });

            return orderedData;
        }

        return new Error('Erro ao listar os registros.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

export const DreService = {
    getAll
};
