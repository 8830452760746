import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import {
  TopFaltantesService,
  ITopFaltante,
} from "../../../../shared/services/api/operacional/ausencias/TopFaltantesServices";

interface TopAtestadosProps {
  onSelectFuncionario: (chapa: string, codColigada: number) => void;
  filterAno: number;
  filterTipo: string;
}

export const TopAtestados: React.FC<TopAtestadosProps> = ({
  onSelectFuncionario,
  filterAno,
  filterTipo,
}) => {
  // Armazena todos os dados retornados do serviço
  const [allAtestados, setAllAtestados] = useState<ITopFaltante[]>([]);
  // Dados filtrados e ordenados para exibição
  const [atestados, setAtestados] = useState<ITopFaltante[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });

  // Função para calcular o total caso não exista o campo TOTAL no backend
  const computeTotal = (item?: ITopFaltante): number => {
    if (!item) return 0;
    return (
      (Number(item.jan) || 0) +
      (Number(item.fev) || 0) +
      (Number(item.mar) || 0) +
      (Number(item.abr) || 0) +
      (Number(item.mai) || 0) +
      (Number(item.jun) || 0) +
      (Number(item.jul) || 0) +
      (Number(item.ago) || 0) +
      (Number(item.set) || 0) +
      (Number(item.out) || 0) +
      (Number(item.nov) || 0) +
      (Number(item.dez) || 0)
    );
  };

  // Busca os dados do serviço uma única vez
  useEffect(() => {
    const fetchAtestados = async () => {
      try {
        const result = await TopFaltantesService.getTopFaltantes();
        if (result instanceof Error) {
          toast.error(result.message);
        } else {
          setAllAtestados(result);
        }
      } catch (error: any) {
        toast.error("Erro ao buscar os dados de Top Atestados");
      } finally {
        setLoading(false);
      }
    };
    fetchAtestados();
  }, []);

  // Atualiza os dados filtrados sempre que allAtestados ou os filtros mudarem
  useEffect(() => {
    const filtered = allAtestados.filter(
      (item) => Number(item.ANO) === filterAno && item.TIPO === filterTipo
    );
    const sorted = filtered.sort(
      (a, b) =>
        (Number(b.TOTAL) || computeTotal(b)) -
        (Number(a.TOTAL) || computeTotal(a))
    );
    setAtestados(sorted);
  }, [allAtestados, filterAno, filterTipo]);

  // Mapeia os dados para as linhas do DataGridPro (garante que a propriedade TOTAL seja acessada como "total")
  const rows = atestados.map((item) => ({
    ...item,
    id: item.CHAPA,
    total: item.TOTAL, // se o backend já enviar TOTAL
  }));

  // Função para tratar o clique no ícone de detalhes
  const handleDetail = (row: any) => {
    if (row && row.CHAPA && row.CODCOLIGADA) {
      onSelectFuncionario(row.CHAPA, row.CODCOLIGADA);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Detalhes",
      flex: 0.8,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        if (!params || !params.row) return null;
        return (
          <IconButton onClick={() => handleDetail(params.row)}>
            <InfoIcon />
          </IconButton>
        );
      },
    },
    { field: "CHAPA", headerName: "Chapa", flex: 0.8 },
    { field: "NOME", headerName: "Nome", flex: 2 },
    { field: "jan", headerName: "Jan", flex: 0.8, type: "number" },
    { field: "fev", headerName: "Fev", flex: 0.8, type: "number" },
    { field: "mar", headerName: "Mar", flex: 0.8, type: "number" },
    { field: "abr", headerName: "Abr", flex: 0.8, type: "number" },
    { field: "mai", headerName: "Mai", flex: 0.8, type: "number" },
    { field: "jun", headerName: "Jun", flex: 0.8, type: "number" },
    { field: "jul", headerName: "Jul", flex: 0.8, type: "number" },
    { field: "ago", headerName: "Ago", flex: 0.8, type: "number" },
    { field: "set", headerName: "Set", flex: 0.8, type: "number" },
    { field: "out", headerName: "Out", flex: 0.8, type: "number" },
    { field: "nov", headerName: "Nov", flex: 0.8, type: "number" },
    { field: "dez", headerName: "Dez", flex: 0.8, type: "number" },
    {
      field: "TOTAL",
      headerName: "Total",
      flex: 1,
      type: "number",
    },
  ];

  if (loading) {
    return <Skeleton variant="rectangular" height={350} />;
  }

  return (
    <>
      {/* Aqui os filtros já podem ser controlados no componente pai */}
      <DataGridPro
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        loading={loading}
        sx={{
          "& .pinkCell": {
            backgroundColor: "#ffe6e6",
            color: "black",
          },
        }}
      />
    </>
  );
};
